import React from 'react';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="text-center">
      <div className="container">
        <div>
          <div className="up-angle bounce-2">
            <a href="#" className="fas fa-angle-up"></a>
          </div>
        </div>
        <p className="copyright">
          Software Engineer
        </p>
        <p className="copyright">
          Copyright © Tashi D. Gyeltshen, {currentYear} All rights reserved.
        </p>
      </div>
    </footer>
  );
}
