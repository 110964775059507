import React from 'react';
import { Link } from 'react-router-dom';

export default function Bio() {
  return (
    <section id="about" className="section-content">
      <div className="d-flex  flex-column container">
        <h2 className="header">Fun Facts</h2>
        <p className="lead bio">
          Originally from the enchanting mountains of Bhutan, now living in
          the US. I'm passionate about exploring new creative hobbies, ranging
          from 3D modeling to photography, origami, crocheting, gardening, and
          playing guitar. I find joy in spending time in nature and have a deep
          love for music. I speak five languages and consider myself a lifelong
          learner, and firmly believe that you can learn anything you put your
          mind to. All it takes is perseverance, discipline and a little bit of
          faith. The journey of learning, for me, is truly endless!
        </p>
        <div className="d-flex bio-links">
          <Link
            to="/gallery"
            className="link link-btn"
          >
            My Gallery
          </Link>
          <a
            href="https://drive.google.com/file/d/1FF8KKQCQKtOb55HAabfbhqC3kYNUufN2/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="link link-btn"
          >
            3D Models
          </a>
        </div>
      </div>
    </section>
  );
}
