import React from 'react';
import './expertise.css';

export default function expertise() {
  return (
    <section id="expertise" className="section-content">
      <div className="container d-flex mx-auto flex-column">
        <h2 className="header">Expertise</h2>
        <p className="lead expertise-lead">
          I've got a multidisciplinary background spanning multiple industries,
          ranging from Aeronautical Engineering, Mechanical 3D Modeling and Design
          to Fullstack Web Development.
        </p>
        <div className="expertise-tiles row no-gutters">
          <ExpertiseItem
            title="Fullstack Web Development"
            icon="fab expertise-icon fa-node-js"
            description="Using tools including HTML/CSS/JavaScript. NodeJs, Express and MongoDB on
              the server with React and Vue on the client."
          />
          <ExpertiseItem
            title="Mobile Web Development"
            icon="fas expertise-icon fa-mobile-alt"
            description="Mobile responsive web design as well as application development
              with Expo and React Native."
          />
          <ExpertiseItem
            title="3D CAD Modeling"
            icon="fas expertise-icon fa-cube"
            description="Engineering 3D Modeling and Design using Solidworks and Catia V5. I also
              design for 3D printing."
          />
        </div>
      </div>
    </section>
  );
}

function ExpertiseItem({ icon, title, description }) {
  return (
    <div className="flip-card col-3-lg">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <div className="front-content row no-gutters">
          <i className={icon + ' col-1'}></i>
          <h3 className="col">{title}</h3>
          </div>
        </div>
        <div className="flip-card-back">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}
