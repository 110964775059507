import React from 'react';
import Cover from './cover';
import Expertise from './expertise';
import Contact from './contact';
import Projects from './projects';
import Bio from './bio';

export default function Home() {
    return (
        <div>
            <Cover />
            <Expertise />
            <Projects />
            <Bio />
            <Contact />
        </div>
    );
}
