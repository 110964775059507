import React from 'react';

export default function Contact() {
  return (
    <section id="contact" className="section-content">
      <div className="container text-center">
        <h2 className="header">Contact</h2>
        <p className="lead">
          If you would like to get in touch, please shoot me an email or reach
          out on one of these social platforms. I'd love to chat and get to
          know you.
        </p>
        <div className="social">
          <a href="https://github.com/JSAssassin" rel="noopener noreferrer" target="_blank">
            <span className="fab fa-github social"></span>
          </a>
          <a href="https://www.linkedin.com/in/tashidgyeltshen/" rel="noopener noreferrer" target="_blank">
            <span className="fab fa-linkedin social"></span>
          </a>
          <a href="mailto:tashidyeltshen@hotmail.com">
            <span className="far fa-envelope social"></span>
          </a>
        </div>
      </div>
    </section>
  );
}
