import React from 'react';
import Footer from './footer';
import Navbar from './navbar';
import Gallery from './gallery';
import Home from './home';
import { Route, Routes } from 'react-router-dom';

export default function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
      <Footer />
    </div>
  );
}
