import React from 'react';
import { items } from './galleryData';
import './gallery.css';

export default function Gallery() {
    return (
        <div className="container">
            <div className="row">
                {items.map((item, index) => (
                    <div key={index} className="col-md-4 mb-4">
                        <div className="card h-100">
                            <img src={item.src} className="card-img-top" />
                            <div className="card-body">
                                {item.description && <p className="card-text">{item.description}</p>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
