import image1 from '../assets/gallery/imgs/image-1.jpg';
import image2 from '../assets/gallery/imgs/image-2.jpg';
import image3 from '../assets/gallery/imgs/image-3.jpg';
import image4 from '../assets/gallery/imgs/image-4.jpg';
import image5 from '../assets/gallery/imgs/image-5.jpg';
import image6 from '../assets/gallery/imgs/image-6.jpg';
import image7 from '../assets/gallery/imgs/image-7.jpg';
import image8 from '../assets/gallery/imgs/image-8.jpg';
import image9 from '../assets/gallery/imgs/image-9.jpg';
import image10 from '../assets/gallery/imgs/image-10.jpg';
import image11 from '../assets/gallery/imgs/image-11.png';
import image12 from '../assets/gallery/imgs/image-12.jpg';
import image13 from '../assets/gallery/imgs/image-13.jpg';
import image14 from '../assets/gallery/imgs/image-14.jpg';
import image15 from '../assets/gallery/imgs/image-15.jpg';
import image16 from '../assets/gallery/imgs/image-16.jpg';
import image17 from '../assets/gallery/imgs/image-17.jpg';
import image18 from '../assets/gallery/imgs/image-18.jpg';
import image19 from '../assets/gallery/imgs/image-19.jpg';
import image20 from '../assets/gallery/imgs/image-20.jpg';
import image21 from '../assets/gallery/imgs/image-21.jpg';
import image22 from '../assets/gallery/imgs/image-22.jpg';
import image23 from '../assets/gallery/imgs/image-23.jpg';
import image24 from '../assets/gallery/imgs/image-24.jpg';
import image25 from '../assets/gallery/imgs/image-25.jpg';
import image26 from '../assets/gallery/imgs/image-26.jpg';
import image27 from '../assets/gallery/imgs/image-27.jpg';
import image28 from '../assets/gallery/imgs/image-28.jpg';
import image29 from '../assets/gallery/imgs/image-29.jpg';

export const items = [
    {
        src: image1,
        description: 'Light projections during one of the Wandering Lights Festival in Tallinn.'
    },
    {
        src: image2,
        description: 'A castle in Prague, in the quiet stillness of the early morning.'
    },
    {
        src: image3,
        description: 'Lights shining like stars on a quiet chilly night at one of the shipping docks in Tallinn.'
    },
    {
        src: image4,
        description: 'Beautiful fall in Latvia, walking behind an elderly woman. This always reminds me of a quote from Tom Stoppard: "We shed as we pick up..."'
    },
    {
        src: image5,
        description: 'An afternoon spent in nature in Charlottesville, capturing photos of insects and wildflowers.'
    },
    {
        src: image6,
        description: 'Bee dancing on the petals of a flower.'
    },
    {
        src: image7,
        description: 'Baby elephant with its mom in Thailand. They say elephants never forget.'
    },
    {
        src: image8,
        description: 'Blanketed in snow, somewhere in Sigulda.'
    },
    {
        src: image9,
        description: 'My first ever crochet project - hyacinth flowers blooming with joy!'
    },
    {
        src: image10,
        description: 'Thanksgiving creations.'
    },
    {
        src: image11,
        description: 'A two-gallon jar filled with paper stars, completed over two years span.'
    },
    {
        src: image12,
        description: 'Wangdue Phodrang, Bhutan.'
    },
    {
        src: image13,
        description: 'Taktshang - Paro, Bhutan.'
    },
    {
        src: image14,
        description: 'Light in darkness.'
    },
    {
        src: image15,
        description: 'Journey to a place unknown. I boarded a public bus in Tallinn and decided to get off at the last stop, which led me to a beautiful remote area in the far north of Tallinn.'
    },
    {
        src: image16,
        description: 'Adorable, innocent face of a cotton-top tamarin.'
    },
    {
        src: image17,
        description: 'Outdoor torches.'
    },
    {
        src: image18,
        description: 'At Suomenlinna Island in Helsinki.'
    },
    {
        src: image19,
        description: 'Kite surfer at Pirita.'
    },
    {
        src: image20,
        description: 'Tallinn Old Town - place that feels straight out of a fairy tale.'
    },
    {
        src: image21,
        description: 'Graffiti decorating an aged wall.'
    },
    {
        src: image22,
        description: 'Friend.'
    },
    {
        src: image23,
        description: 'Strangers, each face with a unique story to tell.'
    },
    {
        src: image24,
        description: 'A deep orange sunset at a beach.'
    },
    {
        src: image25,
        description: 'Just an autumn leaf. Embracing the simple things in life.'
    },
    {
        src: image26,
        description: 'A coneflower, late autumn vegetation.'
    },
    {
        src: image27,
        description: 'This is likely what pride looks like.'
    },
    {
        src: image28,
        description: 'Yin and Yang.'
    },
    {
        src: image29,
        description: 'A church in Moscow, Russia.'
    }
];
