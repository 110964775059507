import React, { Component } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.collapseRef = React.createRef();
    this.buttonRef = React.createRef();
  }
  handleNavItemClick = () => {
    const collapseElement = this.collapseRef.current;
    const buttonElement = this.buttonRef.current;
    if (collapseElement && collapseElement.classList.contains('show')) {
      collapseElement.classList.remove('show');
      buttonElement.classList.add('collapsed');
    }
  };
  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-light bg-light sticky">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            TASHI D. GYELTSHEN
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={this.buttonRef}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarToggler" ref={this.collapseRef}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <HashLink
                  smooth
                  to="/#expertise"
                  className="nav-link link"
                  onClick={this.handleNavItemClick}
                >
                  Expertise
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  smooth
                  className="nav-link link"
                  to="/#projects"
                  onClick={this.handleNavItemClick}
                >
                  Projects
                </HashLink>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link link"
                  to="/gallery"
                  onClick={this.handleNavItemClick}
                >
                  Gallery
                </Link>
              </li>
              <li className="nav-item">
                <HashLink
                  smooth
                  className="nav-link link"
                  to="/#contact"
                  onClick={this.handleNavItemClick}
                >
                  Contact
                </HashLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
