import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import './styles.css';
import { BrowserRouter as Router } from 'react-router-dom';

var mountNode = document.getElementById('app');
const root = createRoot(mountNode);
root.render(
    <Router>
        <App />
    </Router>);
