import React, { Component } from 'react';
import mindFlexImage from '../assets/mindflex.png';
import speedyTenziesImage from '../assets/speedy-tenzies.png';
import sonicConnectImage from '../assets/sonic-connect.png';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './projects.css';

export default class Projects extends Component {
  render() {
    let settings = {
      dots: true,
      infinite: true,
      centerPadding: '200px',
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };
    return (
      <section id="projects" className="section-content">
        <div className="container d-flex mx-auto flex-column">
          <h2 className="header">Projects</h2>
          <Slider {...settings}>
            <ProjectItem
              title="Tenzies"
              description="Tenzies is a fun dice-rolling game developed using React and styled using CSS. Challenge your friends and roll your way to victory!"
              imageSrc={speedyTenziesImage}
              siteUrl="https://speedy-tenzies.netlify.app/"
            />
            <ProjectItem
              title="MindFlex"
              description="MindFlex is a simple quiz developed using React and styled using CSS. It is perfect for a quick mental workout."
              imageSrc={mindFlexImage}
              siteUrl="https://mindflex.netlify.app/"
            />
             <ProjectItem
              title="SonicConnect"
              description="SonicConnect is a robust music management API built with ExpressJS, MongoDB, Mongoose, and GridFile. It follows modern Node.js MVC best practices, employs ESLint to ensure code quality, and is thoroughly tested using Jest."
              imageSrc={sonicConnectImage}
              githubUrl="https://github.com/JSAssassin/sonic-connect"
            />
          </Slider>
        </div>
      </section>
    );
  }
}

function ProjectItem(props) {
  return (
      <div className="project-item mx-2">
        <div className="row no-gutters">
          <div className="col-xl-6">
            <img src={props.imageSrc} target="_blank" className="project-image" width='200px' height="300px"/>
          </div>
          <div className="col-xl-6">
            <h3 className="project-title">{props.title}</h3>
            <p>{props.description}</p>
            {props.githubUrl && (
              <a href={props.githubUrl} target="_blank" className="link link-btn">
                Source
              </a>
            )}
            {props.siteUrl && (
              <a href={props.siteUrl} target="_blank" className="link link-btn">
                Live Demo
              </a>
            )}
          </div>
        </div>
      </div>
    );
}
